







































































































































import Vue from "vue";
import moment from "moment";
import { AxiosResponse } from "axios";
import { getAPI } from "@/api/axios-base";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { notifyError } from "@/components/Notification";

export default Vue.extend({
  components: { MaDatePicker },
  name: "transaction-summary",
  data() {
    return {
      loading: false,
      dateFrom: "",
      dateTo: "",
      dateInterval: "this-year",
      total: 0,
      headers: [
        { text: "Item Type", value: "name", sortable: false },
        {
          text: "Number of sales",
          value: "count",
          align: "end",
          sortable: true,
        },
        {
          text: "Refunds paid",
          value: "refound",
          align: "end",
          sortable: true,
        },
        {
          text: "Gross total",
          value: "total",
          align: "end",
          sortable: true,
        },
      ],
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      items: [],
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  computed: {
    intervalToday() {
      const from = moment();
      from.hour(0);
      from.minute(0);
      from.second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").hour(0).minute(0).second(0);
      const to = moment().subtract(1, "days").hour(23).minute(59).second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().day(0).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").hour(0).minute(0).second(0);
      const to = moment()
        .subtract(1, "week")
        .add(6, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().date(1).hour(0).minute(0).second(0);
      const to = moment().hour(23).minute(59).second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return (this as any).intervarThisMonth;
      }

      const from = moment()
        .subtract(1, "month")
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .date(1)
        .subtract(1, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().month(0).date(1).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment()
        .subtract(1, "year")
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .month(0)
        .date(1)
        .subtract(1, "day")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
  },
  watch: {
    dateFrom(val: string) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getData();
      }
    },
    dateTo(val: string) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getData();
      }
    },
  },
  mounted() {
    this.setIntervar(this.dateInterval);
  },
  methods: {
    setIntervar(interval: string) {
      this.dateInterval = interval;
      this.getData();
    },
    getData() {
      let interval;

      switch (this.dateInterval) {
        case "today":
          interval = this.intervalToday;
          break;
        case "yesterday":
          interval = this.intervarYesterDay;
          break;
        case "this-week":
          interval = this.intervarThisWeek;
          break;
        case "last-week":
          interval = this.intervarLastWeek;
          break;
        case "this-month":
          interval = this.intervarThisMonth;
          break;
        case "last-month":
          interval = this.intervarLastMonth;
          break;
        case "this-year":
          interval = this.intervarThisYear;
          break;
        case "last-year":
          interval = this.intervarLastYear;
          break;
        case "select":
          interval = {
            date1: moment(this.dateFrom).toISOString(),
            date2: moment(this.dateTo).toISOString(),
          };
          break;
      }

      this.loading = true;
      this.query = interval;
      getAPI
        .post("/payment/salesByProduc", interval)
        .then((response: AxiosResponse) => {
          const responseData = response.data;
          let items = [
            ...responseData.items,
            {
              name: "total",
              count: responseData.total.count,
              refound: responseData.total.refound,
              total: responseData.total.total,
            },
          ];
          // console.log(responseData);

          // const dataValues = responseData.filter(
          //   (item: any) => item.payment_type !== "TOTAL"
          // );

          (this as any).items = items;
          this.loading = false;
          this.total = parseFloat(responseData.total.total);
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    prettyDate(date: string): string {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
  },
});
